/* === CHECKOUT PAGE SCRIPT === */
require(['jquery'], function ($) {
    if (location.pathname.includes('checkout/onepage')) {
        $('input#phone').attr({ 'maxlength': '10', 'minlength': '10', 'type': 'tel' });

        const checkoutMutationObserver = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                console.log(mutation);
                $('input#phone').attr({ 'maxlength': '10', 'minlength': '10', 'type': 'tel', 'pattern': '(?=0)[0-9]+' });
            });
        });

        checkoutMutationObserver.observe(document.getElementById('opc-billing'), { attributes: true });
        checkoutMutationObserver.observe(document.getElementById('opc-shipping'), { attributes: true });
    }
});